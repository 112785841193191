<template>
  <div class="app">
    <!-- <div class="app-body" :style="d_showAplicationHeader ? 'margin-bottom: 0px;' : 'margin-top: 0px;'"> -->
    <div :style="d_showAplicationHeader ? 'margin-bottom: 0px; overflow-x: hidden;' : 'margin-top: 0px; overflow-x: hidden;'">
      <div class="navbar_class">
        <b-row style="margin: 0px;">
          <b-col cols="5">
            <img @click="d_showAplicationSidebar ? d_showAplicationSidebar = false : d_showAplicationSidebar = true" src="@/icon/1324908.png" style="width: 50px;" />
            <span v-if="!StoreDevice.isMobile" :style="'text-shadow: -1px -1px ' + d_websiteData.navbar_app_name_on_left_text_shadow_color + '; font-size: 20px; cursor: pointer;'" @click="f_goToMainPage()" title="Anasayfa"> {{ d_websiteData.name }}</span>
            <!-- <img :src="d_websiteData.header.middle_logo_base64" height="35px" /> -->
          </b-col>
          <b-col v-if="!StoreDevice.isMobile" cols="2" style="text-align: center; margin-top: 10px;">
            <strong style="cursor: pointer; font-size: 16px;" @click="f_goToMainPage()">
              <img :src="d_websiteData.header.middle_logo_base64" style="width: 2em;" />
            </strong>
          </b-col>
          <b-col :cols="StoreDevice.isMobile ? 7 : 5">
            <b-button v-if="d_user && d_user.username" title="Çıkış Yap" @click="f_logout()" size="lg" style="padding: 0px;" class="pull-right" variant="white"><img src="@/icon/429539.png" width="40px;" />
              <span v-if="!StoreDevice.isMobile" style="font-family: cursive; font-size: 14px; padding: 3px; text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);"> {{ f_showUserName() }} </span>
            </b-button>
            <b-button v-if="!d_user" title="Login" @click="f_login()" size="lg" style="padding: 0px;" class="pull-right" variant="white"><img src="@/icon/633951.png" width="40px;" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-if="d_showAplicationSidebar" class="sidebar_class general_scroll_class" :style="f_sideBarStyle()">
        <b-row class="menu-item">
          <b-col cols="12" :style="'color: ' + d_websiteData.menu_item_text_color + '; cursor: pointer;'">
            <!-- <img :src="d_websiteData.header.middle_logo_base64" style="width: 20px; margin-left: -5px;" /> -->
            <strong> {{ d_websiteData.sidebarmenutopitem.caption }} </strong>
          </b-col>
        </b-row>
        <template v-for="(menu_item, menu_item_index) in d_websiteData.page_list">
          <b-row class="menu-item" :style="f_calculateMenuItemStyle(menu_item.route_name)">
            <template v-if="menu_item.route_path">
              <b-col cols="12" @click="f_goToThisPage(menu_item)">
                <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> -->
                <i class="fa fa-angle-right"></i>
                <!-- <img :src="d_websiteData.header.middle_logo_base64" style="width: 20px; margin-left: -5px;" /> -->
                <strong> {{ menu_item.caption }} </strong>
                <span style="float: right;"> <img v-if="menu_item.login === 1" src="@/icon/9370.png" style="width: 10px;" /> </span>
              </b-col>
            </template>
            <template v-else-if="menu_item.url">
              <b-col cols="12" @click="f_goToThisUrl(menu_item.url)">
                <i class="fa fa-angle-right"></i> {{ menu_item.caption }} <img src="@/icon/2230573.png" style="width: 30px;" />
              </b-col>
            </template>
            <template v-else>
              <b-col cols="12">
                <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-right"></i> {{ menu_item.caption }}
              </b-col>
            </template>
          </b-row>
          <template v-if="menu_item.sub_menu && menu_item.sub_menu.length > 0" v-for="(sub_menu_item, sub_menu_item_index) in menu_item.sub_menu">
            <b-row class="sub-menu-item" :style="f_calculateMenuItemStyle(sub_menu_item.route_name)">
              <template v-if="menu_item.route_path">
                <b-col cols="12" style="cursor: pointer;" @click="f_goToThisPage(sub_menu_item)">
                  <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> {{ sub_menu_item.caption }}
                  <span style="float: right;"> <img v-if="menu_item.login === 1" src="@/icon/9370.png" style="width: 10px;" /> </span>
                </b-col>
              </template>
              <template v-else-if="sub_menu_item.url">
                <b-col cols="12" @click="f_goToThisUrl(sub_menu_item.url)">
                  <i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption }} <img src="@/icon/2230573.png" style="width: 30px;" />
                </b-col>
              </template>
              <template v-else>
                <b-col cols="12">
                  <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption }}
                </b-col>
              </template>
            </b-row>
          </template>
        </template>
      </div>
      <main class="text-class" :style="f_calculateMainAreaStyle()">
        <div id="router-div" style="margin-top: 50px;">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <b-modal id="store-loading" v-model="d_showLoadingModal" no-close-on-esc no-close-on-backdrop centered hide-footer hide-header-close body-bg-variant="dark" style="border-radius: 30px!important; z-index: 9999; box-shadow: -1px -1px 1px;">
      <b-row v-if="StoreLoading.data.label" style="vertical-align: middle; text-align: center;">
        <b-col sm="12" lg="1">
          <img src="../../public/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" />
        </b-col>
        <b-col sm="12" lg="11">
          <div style="padding-top: 5px;">{{ StoreLoading.data.label }}</div>
          <template v-if="d_progressData">
            <b-progress :value="d_progressData.percent" variant="success" show-progress striped :animated="true" class="mb-2"></b-progress>
            <div style="padding-top: 5px;">
              <span v-if="d_progressData.total_process_count"> Toplam İşlem: {{ d_progressData.total_process_count }} </span>
              <span v-if="d_progressData.process_count">İşlem Adedi: {{ d_progressData.process_count }} </span>
            </div>
            <div v-if="d_progressData.message" style="padding-top: 5px;"> Mesaj: {{ d_progressData.message }} </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="store-modal" v-model="d_showStoreModal" :title="StoreModal.data.title ? StoreModal.data.title : 'Uyarı'" :centered="StoreModal.data.centered ? StoreModal.data.centered : true" :header-bg-variant="StoreModal.data.header_bg_variant ? StoreModal.data.header_bg_variant : 'info'" :header-text-variant="StoreModal.data.header_text_variant ? StoreModal.data.header_text_variant : 'dark'">
      <div style="white-space: pre-line;" v-if="StoreModal.data.text">{{ StoreModal.data.text }}</div>
      <template slot="modal-footer">
        <b-button v-if="StoreModal.data.type === 'alert'" @click="d_showStoreModal = false">ok</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="d_showStoreModal = false">cancel</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="f_confirmStoreModal()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _menu from '@/_menu';
import auth from '@/auth';
import moment from "moment";
import { mapGetters } from 'vuex';
import { version } from '@/config';
import {
  default as Modal
} from '@/components/widgets/Modal';
// import {default as WisdomSocket } from '@/components/socket';

export default {
  name: 'DefaultContainer',
  components: {
    Modal,
    // WisdomSocket
  },
  computed: {
    ...mapGetters({
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  data () {
    return {
      d_notShowBarRoutes: [],
      d_progressData: '',
      d_showAplicationHeader: true,
      d_showAplicationSidebar: true,
      d_showLoadingModal: false,
      d_showStoreModal: false,
      d_websiteData: _menu.website_data,
      d_user: '',
      d_version: version,
    }
  },
  created: function () {
    // console.log(this.StoreUser);
    this.d_user = JSON.parse(localStorage.getItem('user'));
    if (this.d_notShowBarRoutes.indexOf(this.$route.name) !== -1) {
      this.d_showAplicationHeader = false;
      this.d_showAplicationSidebar = false;
    }
    if (this.StoreDevice.isMobile) {
      this.d_showAplicationSidebar = false;
    }
  },
  destroyed () {},
  beforeCreate: function () {},
  mounted: function () {
    if (this.StoreLoading.status) {
      this.d_showLoadingModal = true;
    }
  },
  methods: {
    f_goToMainPage: function () {
      this.$router.push({ path: this.d_websiteData.routes.dashboard.path, query: {} });
    },
    f_logout: function () {
      this.$router.push({ 'path': '/logout', 'query': {} });
    },
    f_login: function () {
      this.$router.push({ 'path': '/login', 'query': {} });
    },
    f_calculateMenuItemStyle: function (route_name) {
      let style = '';
      if (this.$route.name === route_name) {
        // style = 'background-color: rgb(12, 147, 65);';
        style = 'background-color: ' + this.d_websiteData.menu_item_selected_backgroud_color + ' ;';
      }
      return style;
    },
    f_calculateMainAreaStyle: function () {
      let style = '';
      if (this.d_showAplicationSidebar) {
        if (this.StoreDevice.isMobile) {
          style = 'padding: 10px; margin-left: 0px; width: 100%;';
        } else {
          style = 'padding: 10px; margin-left: 325px; width: 100%;';
        }
      } else {
        if (this.StoreDevice.isMobile) {
          style = 'padding: 10px; margin-left: 0px; width: 100%;';
        } else {
          style = 'padding: 10px; margin-left: 0px; width: 100%;';
        }
      }
      return style;
    },
    f_sideBarStyle: function () {
      let style = 'height: ' + (window.innerHeight - 50).toString() + 'px;';
      return style;
    },
    f_goToThisPage: function (menu_item) {
      // console.log('menu_item.route_path : ', menu_item.route_path);
      if (this.d_user && this.d_user.username) {
        if (this.$route.path !== menu_item.route_path) {
          this.$router.push({ 'path': menu_item.route_path, 'query': {} });
        }
      } else {
        if (menu_item.login) {
          this.$router.push({ 'path': '/login', 'query': {} });
        } else {
          if (this.$route.path !== menu_item.route_path) {
            this.$router.push({ 'path': menu_item.route_path, 'query': {} });
          }
        }
      }
      if (this.StoreDevice.isMobile) {
        this.d_showAplicationSidebar = false;
      }
    },
    f_goToThisUrl: function (url) {
      window.open(url, '_blank');
      if (this.StoreDevice.isMobile) {
        this.d_showAplicationSidebar = false;
      }
    },
    f_confirmStoreModal: function () {
      let data = JSON.parse(JSON.stringify(this.StoreModal.data));
      data.confirm = true;
      this.$store.commit('MutationModal', data);
      this.d_showStoreModal = false;
    },
    f_showProgressBarByInterval: function () {
      this.showProgresBarInterval = setInterval(() => {
        if (!this.d_progressData) {
          this.d_progressData = {};
        }
        if (this.d_progressData.percent === undefined) {
          this.d_progressData.percent = 5;
        } else {
          if (this.d_progressData.percent <= 50) {
            this.d_progressData.percent += 4;
          } else if (this.d_progressData.percent > 50 && this.d_progressData.percent <= 75) {
            this.d_progressData.percent += 3;
          } else if (this.d_progressData.percent > 75 && this.d_progressData.percent <= 85) {
            this.d_progressData.percent += 2;
          } else if (this.d_progressData.percent > 85 && this.d_progressData.percent < 99) {
            this.d_progressData.percent += 1;
          }
        }
        this.$forceUpdate();
      }, 500);
    },
    f_showUserName: function () {
      let result_name = '';
      if (this.d_user && this.d_user.first_name) {
        let result_name_list = [];
        let first_name = this.d_user.first_name;
        let last_name = this.d_user.last_name;
        let first_name_list = first_name.split(' ');
        for (let i in first_name_list) {
          if (parseInt(i) + 1 === first_name_list.length) {
            result_name_list.push(first_name_list[i]);
          } else {
            result_name_list.push(first_name_list[i][0]);
          }
        }
        let last_name_list = last_name.split(' ');
        for (let i in last_name_list) {
          if (parseInt(i) + 1 === last_name_list.length) {
            result_name_list.push(last_name_list[i]);
          } else {
            result_name_list.push(last_name_list[i][0]);
          }
        }
        result_name = result_name_list.join(' ');
      }
      return result_name;
    }
  },
  watch: {
    'd_showStoreModal': function () {
      if (!this.d_showStoreModal) {
        this.$store.commit('MutationModalShow', false);
      }
    },
    'StoreDevice.isMobile': function () {
      console.log('this.StoreDevice.isMobile : ', this.StoreDevice.isMobile);
    },
    'StoreUser.status': function () {
      console.log('this.StoreUser : ', this.StoreUser.status);
    },
    'StoreLoading.status': function () {
      if (this.StoreLoading.status) {
        this.d_showLoadingModal = true;
        this.d_progressData = '';
        this.f_showProgressBarByInterval();
      } else {
        this.d_showLoadingModal = false;
        this.d_progressData = '';
        clearInterval(this.showProgresBarInterval);
      }
    },
    'StoreModal.show': function () {
      if (this.StoreModal.show) {
        this.d_showStoreModal = true;
      } else {
        this.d_showStoreModal = false;
        if (this.StoreModal.data.function_name) {
          if (this.StoreModal.data.function_name === 'f_exampleMethod' && this.StoreModal.data.confirm) {
            // this.f_exampleMethod();
          }
        }
      }
    }
  }
}

</script>

<style type="text/css">


</style>

